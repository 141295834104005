
.ttext-light {
    transform: translate(0, 3%);
}

_::-webkit-full-page-media, _:future, :root .ttext {
    transform: translate(0, 10%);
}

@media not all and (min-resolution:.001dpcm) { @media {
    .ttext { 
        transform: translate(0, 10%);
    }
}}

/* CSS specific to iOS devices */
@supports (-webkit-touch-callout: none) {
    .ttext {
        transform: translate(0, 12%);
    }
}

/* Safari */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .ttext {
        transform: translate(0, 12%);
    }
}}
