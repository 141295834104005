.ce-block__content {
    max-width: none;
}

.ce-toolbar__content {
    max-width: none;
}

.codex-editor__redactor {
    padding-bottom: 20px !important;
}

.codex-editor {
    z-index: 999;
}