.control-panel {
    width: 350px;
}

.expanded-control-panel {
    width: 800px;
}

.control-panel .icon-filter,.icon-search {
    display: none;
}

.icon-filter {
    margin-left: 15px;
}

.search-input {
    min-width: 125px;
}

/* .search-input-popover { */
    /* margin: 10px; */
/* } */

@media screen and (max-width: 650px) {
    .control-panel .icon-filter,.icon-search {
        display: block;
        cursor: pointer;
    }   

    .control-panel .text-filter,.search-input {
        display: none !important;
    }

    .control-panel, .expanded-control-panel {
        width: 100px;
    }

}