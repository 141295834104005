.swiper-days {
    width: 100%;
}

.swiper-days swiper-slide {
    width: max-content;
}

@media screen and (max-width: 650px) {
    .show-schedule__arrow {
        height: 12px;
    }
}

