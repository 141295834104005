.eh1 {
    margin: 10 0 20px !important;
    margin: 20px 0 10px !important;
}

.eh2, .eh3, .eh4, .eh5, .eh6 {
    margin: 20px 0 10px !important;
}

.ep {
    margin: 0 !important;
    font-size: 18px;
    padding: 0.5em 0;
}

.elist__container {
    padding-left: 1.5em;
}

ul.elist__container {
    list-style: disc inside;
}

.elist__item {
    font-size: 18px;
}

.ewarning {
    padding: 20px;
    background: #fffad0;
    border-radius: 10px;
    margin: 10px 0;
}

.ewarning__title-container {
    display: flex;
    padding-bottom: 10px;
    padding-left: 10px;
}

.ewarning__icon {
    margin-right: 15px;
}

.ewarning__title {
    font-weight: bold;
}

.ewarning__message {
    padding-left: 15px
}

.efigure {
    max-height: unset !important;
}

.eimg {
    max-height: unset !important;
}

.efigcaption {
    position: relative !important;
    top: unset !important;
    right: unset !important;
    background: unset !important;
    color: gray !important;
}

.equote__content {
    background: rgb(213, 233, 243);
    padding: 20px;
    border-radius: 10px;
}

.equote__message {
    font-size: 18px;
    font-style: italic;
}

@media screen and (max-width: 959px) {
    .ep {
        font-size: 16px;
    } 

    .elist__item {
        font-size: 16px;
    }
}