/* Font DIN Condensed: Copyright ? ParaType, 2018. All rights reserved.*/

@font-face {
    font-family: 'DIN Condensed';
    src: local('DIN Condensed Light'),
        url('fonts/DIN Condensed/DIN-Condensed_Light.woff2') format('woff2'),
        url('fonts/DIN Condensed/DIN-Condensed_Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Condensed';
    src: local('DIN Condensed'),
        url('fonts/DIN Condensed/din-condensed_normal.woff2') format('woff2'),
        url('fonts/DIN Condensed/din-condensed_normal.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a, a:hover, a:focus, a:active {
  text-decoration: none !important;
  color: inherit !important;
}