.tinput label {
    border-radius: 0;
    box-shadow: 0 0 0 2px black;
    height: 35px;
}

.tinput.light label {
    box-shadow: 0 2px 0 0 black;
}

.tinput.light input {
    font-size: 18px;
    font-weight: 300;
    margin: 0;
}

.tinput input {
    font-family: 'DIN Condensed';
    font-size: 20px;
}

.ttext-light {
    transform: translate(0, 3%);
}

_::-webkit-full-page-media, _:future, :root .tinput input {
    transform: translate(0, 10%);
}

@media not all and (min-resolution:.001dpcm) { @media {
    .tinput input { 
        transform: translate(0, 10%);
    }
}}

/* CSS specific to iOS devices */
@supports (-webkit-touch-callout: none) {
    .tinput input {
        transform: translate(0, 12%);
    }
}

/* Safari */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .tinput input {
        transform: translate(0, 12%);
    }
}}

/*  */

