.show-gallery__image {
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left: 50.666666px;
    height: 200px; 
    width: 300px;
    position: relative;
    cursor: pointer;
}

.show-gallery__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.show-gallery__image .show-gallery__last-image-button {
    position: absolute;
    inset: 0;
    background: rgba(143, 37, 18, 0.52);
}


.show-gallery__image .show-gallery__last-image-button p {
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    width: max-content;
}

.show-gallery__slider-wrapper {
    position: fixed;
    inset: 0;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(5px);
    z-index: 999;
    background: rgb(0, 0, 0, 0.5);
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    overscroll-behavior: none;
}

.show-gallery__slider {
    position: absolute;
    inset: 0;
}

.show-gallery__slider_button-wrapper {
    position: absolute !important;
    z-index: 9999;
    width: max-content !important;
    padding: 15px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 100%;
    cursor: pointer;
}

.show-gallery__slider_close-button {
    right: 25px;
    top: 25px;
}

.show-gallery__slider_left-button {
    left: 25px;
    top: 50%;
    transform: translate(0, -50%);
}

.show-gallery__slider_right-button {
    right: 25px;
    top: 50%;
    transform: translate(0, -50%);
}

.show-gallery__slider swiper-slide img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.show-gallery__slider-wrapper .swiper-button-disabled {
    opacity: 0.5;
}

.hidden {
    display: none;
}

.show-gallery__mobile-gallery {
    padding: 20px 0 10px 0;
}

.show-gallery__mobile-gallery swiper-container {
    height: 250px;
    --swiper-pagination-color: #8F2512;
}

.show-gallery__mobile-gallery_image-wrapper {
    position: relative;
    width: 100%;
    height: calc(100% - 25px);
    overflow: hidden; 
}


.show-gallery__mobile-gallery_image-wrapper div {
    position: absolute;
    inset: 0;
    z-index: -1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: blur(3px);
    transform: scale(1.2);
}


.show-gallery__mobile-gallery .image {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
    z-index: 9999;
}


@media screen and (max-width: 649px) { /* xs bp */
    .show-gallery__image {
        height: 200px;
        width: 300px;
    }

    .show-gallery__slider_left-button {
        left: 50%;
        top: unset;
        bottom: 50px;
        transform: translate(-150%, 0);
    }

    .show-gallery__slider_right-button {
        left: 50%;
        top: unset;
        bottom: 50px;
        transform: translate(50%, 0);
    }
}
@media screen and (min-width: 650px) and (max-width: 1400px) { /* sm bp */
    .show-gallery__image {
        height: 200px;
        width: 285px;
        margin: 16px 0 16px 32px;
    } 
}

@media screen and (min-width: 650px) and (max-width: 959px) { /* only sm bp */
    .show-gallery__image:nth-child(2n + 1) {
        margin-left: 0;
    }
}

@media screen and (min-width: 960px) and (max-width: 1400px) { /* md bp */
    .show-gallery__image {
        height: 200px;
        width: 284px;
        margin: 15px 0 15px 30px;
    } 
}

@media screen and (min-width: 960px) and (max-width: 1399px) { /* only md and lg bp */
    .show-gallery__image:nth-child(3n + 1) {
        margin-left: 0;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1400px) { /* lg bp */
    .show-gallery__image {
        height: 251px;
        width: 377.3333333333px;
        margin: 25px 0 25px 50px;
    }
}

@media screen and (min-width: 1400px) { /* xl bp */
    .show-gallery__image:nth-child(4n + 1) {
        margin-left: 0;
    }
}