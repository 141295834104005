.tcard_main-page {
    cursor: pointer;
    transition: 200ms;
    user-select: none;
    width: 340px;
}

.tcard_main-page__body {
    margin-bottom: 15px;
    user-select: none;
    pointer-events: none;
    overflow: hidden;
}

.tcard_main-page__body img {
    width: 340px;
    height: 486px;
    object-fit: cover;
    overflow: hidden;
}

.tcard_main-page__footer p {
    font-size: 48px;
}

.pressed {
    transform: scale(0.95);
}

@media screen and (max-width: 1280px) {
    .tcard_main-page {
        width: 280px;
    } 

    .tcard_main-page__body img {
        height: 400px;
    }

    .tcard_main-page__footer p {
        font-size: 36px;
    }
}

@media screen and (max-height: 800px) {
    .tcard_main-page {
        width: 250px;
    }

    .tcard_main-page__body img {
        height: 357px;
    }

    .tcard_main-page__footer p {
        font-size: 32px;
    }
}

@media screen and (max-height: 650px) {
    .tcard_main-page {
        width: 180px;
    }

    .tcard_main-page__body img {
        height: 257px;
    }

    .tcard_main-page__footer p {
        font-size: 24px;
    }
}
