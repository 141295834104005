.question-item-dsktp {
    padding: 20px 10px 20px 10px;
    cursor: pointer;
    transition: 200ms;
}

.question-item-dsktp__active {
    background: rgba(143, 37, 18, 1);
}

.question-item-dsktp__active p {
    color: white;
}

.question-item-dsktp:hover {
    background: rgba(143, 37, 18, 1);
}

.question-item-dsktp:hover p {
    color: white;
}