.horizontal-slider__view {
    position: relative;
    overflow-y: hidden;
    overflow-x: scroll;
    height: max-content;
    width: 100%;
    scroll-behavior: smooth;
}

.horizontal-slider__view::-webkit-scrollbar {
    display: none;
}