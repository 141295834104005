.news-slide-container {
    display: grid;
    /* grid-template-rows: 1fr 1fr; */
    grid-template-columns: 1fr 1fr;
    column-gap: 100px;
    height: 800px;
}

.tcard_main-page.news {
    width: 500px;
    /* margin-bottom: 15px; */
}

.tcard_main-page.news .tcard_main-page__body img {
    width: 500px;
    height: 300px;
}

.tcard_main-page.news .tcard_main-page__footer p {
    font-size: 32px;
}

.pressed {
    transform: scale(0.95);
}


@media screen and (max-width: 1280px) {
    .tcard_main-page.news {
        width: 400px;
    }

    .tcard_main-page.news .tcard_main-page__body img{
        width: 400px;
        height: 250px;
    }

    .news-slide-container {
        height: 700px;
    }

    .tcard_main-page.news .tcard_main-page__footer p {
        font-size: 27px;
    }
}

@media screen and (max-height: 1000px) {
    .tcard_main-page.news {
        width: 400px;
    } 

    .tcard_main-page.news .tcard_main-page__body img{
        width: 400px;
        height: 250px;
    }

    .news-slide-container {
        height: 700px;
    }

    .tcard_main-page.news .tcard_main-page__footer p {
        font-size: 27px;
    }
}

@media screen and (max-width: 960px) {
    .news-slide-container {
        height: 540px;
        grid-template-columns: 1fr;
    }

    .tcard_main-page.news {
        width: 300px;
    } 

    .tcard_main-page.news .tcard_main-page__body img {
        width: 300px;
        height: 200px;
    }

    .tcard_main-page.news .tcard_main-page__footer p {
        font-size: 21px;
    }
}

@media screen and (max-height: 900px) {
    .tcard_main-page.news {
        width: 300px;
    } 

    .tcard_main-page.news .tcard_main-page__body img {
        height: 200px;
        width: 300px;
    }

    .news-slide-container {
        height: 540px;
        column-gap: 50px;
    }

    .tcard_main-page.news .tcard_main-page__footer p {
        font-size: 21px;
    }
}

@media screen and (max-height: 750px) {
    .news-slide-container {
        grid-template-columns: 1fr 1fr;
        height: unset;
    } 
}

@media screen and (max-width: 960px) {
    .news-slide-container {
        grid-template-columns: 1fr;
        height: 450px
    }

    .tcard_main-page.news {
        width: 250px;
    }

    .tcard_main-page.news .tcard_main-page__body img {
        width: 250px;
        height: 150px;
    }

    .tcard_main-page.news .tcard_main-page__footer p {
        font-size: 18px;
    }
}