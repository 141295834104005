.not-found__image {
    width: 500px;
}

@media screen and (max-width: 649px) { /* xs bp */
    .not-found__image {
        width: 300px;
    }
}
@media screen and (min-width: 650px) and (max-width: 1399px) { /* sm bp */
    .not-found__image {
        width: 300px;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1399px) { /* lg bp */
    .not-found__image {
        width: 400px;
    }
}